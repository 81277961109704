<template>
  <div class="setting" v-if="setting">
    <h2>Banner</h2>
    <div class="list">
      <div class="item" v-for="(banner, index) in setting.banner" :key="index">
        <a-form
          layout="horizontal"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 19 }"
          label-align="left"
        >
          <img class="banner" :src="banner.url" />
          <a-form-item label="图片URL(EN)">
            <a-input v-model="banner.url" placeholder="请输入（必填）" />
          </a-form-item>
          <!-- <a-form-item label="图片URL(CN)">
            <a-input v-model="banner.zhUrl" placeholder="请输入（可选）" />
          </a-form-item> -->
          <a-form-item label="标题">
            <a-input v-model="banner.title" placeholder="请输入（必填）" />
          </a-form-item>
          <div class="meta">
            <a-descriptions :column="2">
              <a-descriptions-item label="是否可见">
                <a-switch size="small" v-model="banner.isDisplay" />
              </a-descriptions-item>
              <a-descriptions-item label="外部链接">
                <a-switch size="small" v-model="banner.extra" />
              </a-descriptions-item>
            </a-descriptions>
          </div>
          <a-form-item label="跳转地址/路径">
            <a-input v-model="banner.path" placeholder="跳转地址/路径" />
          </a-form-item>
          <div class="btn">
            <a-button type="danger" @click="onDeleteClick(index)">
              删除
            </a-button>
          </div>
        </a-form>
      </div>
    </div>
    <div class="btns">
      <a-space>
        <a-button type="default" @click="onAddClick">添加</a-button>
        <a-button type="primary" :loading="loading" @click="onSaveClick">
          保存
        </a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Setting',
  data() {
    return {
      loading: false,
      setting: null,
    };
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    async getSetting() {
      const { err, res } = await this.$api.setting.info();

      if (!err) {
        this.setting = res.result;
      }
    },

    async onSaveClick() {
      this.loading = true;
      const { err, res } = await this.$api.setting.updateBanner(this.setting);

      if (!err) {
        this.$message.success(res.msg);
      }
      this.loading = false;
    },

    async onDeleteClick(index) {
      this.setting.banner.splice(index, 1);
    },

    onAddClick() {
      this.setting.banner.push({
        isDisplay: true,
        url: '',
        extra: false,
        path: '',
        // zhUrl: '',
        title: '',
      });
    },
  },
};
</script>

<style lang="less" scoped>
.setting {
  .list {
    display: flex;
    .item {
      width: 540px;
      margin-right: 16px;
      border: #ccc solid 1px;
      border-radius: 5px;
      padding: 20px;
      .banner {
        width: 300px;
        margin-bottom: 16px;
      }
      .meta {
        margin-top: 16px;
      }
      .btn {
        margin-top: 20px;
      }
    }
  }
  .btns {
    margin-top: 32px;
  }
}
</style>
